.gameListSection {
    padding: 1.5rem 0 1rem;
}

.gameListSection .section-outer .title-wrapper {
    color: var(--cta);
    font-weight: 900;
    display: flex;
    justify-content: space-between;
    align-items: center;
    letter-spacing: unset;
    width: 100%;
    margin: 0 auto 0.2rem;
    line-height: 25px;
}

.gameListSection .title-wrapper .title-text {
    color: white;
}

.gameListSection .section-outer .title-wrapper .title-text .title {
    display: flex;
    align-items: center;
}

.gameListSection .title-wrapper .title-text h4 {
    font-weight: 600;
    font-family: "Gilroy", sans-serif !important;

}

.gameListSection .section-outer .title-wrapper .see-all {
    color: #720494;
}

.gameListSection .section-outer .title-wrapper .see-all {
    font-weight: 400;
    font-size: .875rem;
    cursor: pointer;
    text-transform: uppercase;
    display: flex;
}


/* ------------------- */


.gameListSection .section-outer .inner-section .container-inner {
    flex-wrap: wrap;
}

.gameListSection .section-outer .inner-section .container-inner {
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    flex-wrap: nowrap;
    width: 100%;
    padding-top: 10px;
    justify-content: unset;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    grid-gap: 1%;
    gap: 1%;
}

.gameListSection .section-outer .inner-section .column-size-6 .card-wrapper {
    width: 100%;
}

.gameListSection .section-outer .inner-section .card-wrapper {
    margin-bottom: 10px;
}


.gameListSection .section-outer .inner-section .card-wrapper:hover {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
}

.gameListSection .section-outer .inner-section .card-wrapper {
    transition: all .1s ease;
    border-radius: 10px;
    position: relative;
    cursor: pointer;
    flex-shrink: 0;
    width: 19.2%;
}

.gameListSection a {
    color: #000;
}

.gameListSection .section-outer .inner-section .card-wrapper .card-game-list {
    position: relative;
    transition: all .2s ease-out;
}

.gameListSection .section-outer .inner-section .card-wrapper .card-game-list .bg,
.gameListSection .section-outer .inner-section .card-wrapper .card-game-list .error-bg {
    width: 100%;
    border-radius: 10px;
    transition: all .4s ease-in-out;
    -webkit-transform: scale(1);
    transform: scale(1);
    box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, .4);
}

img,
svg {
    vertical-align: middle;
}


.jackpot-container {
    position: absolute;
    bottom: 43px;
    width: 100%;
    text-align: center;
}

.jackpot-container .jackpot-amount {
    display: inline-block;
    text-align: center;
    padding: 2px 10px;
    margin: 0 auto 5px;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, .5);
    color: var(--text);
    font-size: .9rem;
    font-weight: 700;
}

/* -------section -------------- */
.taglines .tagline.title {
    margin-top: 2rem;
}

.taglines .tagline.title {
    margin-top: 2rem;
}

.taglines .tagline.title span {
    font-size: 24px;
}

.tagline {
    margin-bottom: 5px;
}

.taglines .tagline.title span {
    font-family: "Gilroy", sans-serif !important;
}

.taglines .tagline.text span {
    font-size: 16px;
    line-height: 1;
    margin-bottom: 1rem;
}

.tagline span {
    display: block;
    font-weight: 600;
}


.taglines .tagline.btn.btn-cta {
    margin-top: 1rem;
    width: -moz-fit-content;
    width: fit-content;
}

.btn span,
a.btn:not([href]):not([tabindex]) span,
button.btn span {
    font-family: Asap, sans-serif;
    font-family: var(--button-font);
    letter-spacing: 0;
    letter-spacing: var(--button-letter-spacing);
}

.per-row:nth-child(even) {
    direction: rtl;
}

.btn {
    border-radius: 2.5rem;
    border-radius: var(--button-border-radius);
    font-family: Asap,
        sans-serif;
    font-family: var(--button-font);
    letter-spacing: 0;
    letter-spacing: var(--button-letter-spacing);
    box-shadow: none;
    border: none;
    font-size: 16px;
    text-transform: uppercase;
    height: 54px;
    padding: 15px;
    min-width: 192px;
    font-weight: 600;
    cursor: pointer;
    white-space: nowrap;
}


.btn span,
a.btn:not([href]):not([tabindex]) span,
button.btn span {
    font-family: Asap, sans-serif;
    font-family: var(--button-font);
    letter-spacing: 0;
    letter-spacing: var(--button-letter-spacing);
}

.tagline.winner {
    font-family: "Gilroy", sans-serif !important;
    font-size: 24px;
}

/* -------section end-------------- */
/* .row .taglines-group .middle {
    display: flex;
    align-items: end;
} */

/* .row .taglines-group {
    display: flex;
    justify-content: space-between;
    padding-left: 0;
    padding-right: 0;
} */

.taglines-group {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
}



.row .taglines-group .top-winner {
    display: flex;
}

.tagline.game-icon img {
    margin-right: 1rem;
    border-radius: 10px;
}

.tagline.winner-detail {
    margin-right: 1rem;
}

.tagline {
    margin-bottom: 5px;
}

.tagline.winner-detail h4 {
    font-size: 20px;
    font-weight: 900;
    margin-bottom: 0.2rem;
    font-family: "Gilroy", sans-serif !important;
}

.taglines-group .top-winner .tagline.win-price {
    background-image: url(https://images.ctfassets.net/scslmvjv6lim/2iTpWQ2gfQQvolUpTcf6SP/389647de98646c11bb657b5c8d2a14af/badge-top-winners.svg);
    height: 85px;
    width: 85px;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.tagline.win-price span:nth-child(1) {
    font-weight: 900;
    font-size: 14px;
    text-align: center;
    line-height: 10px;
}

.tagline.win-price span:nth-child(2) {
    font-size: 18px;
    font-weight: 900;
    text-align: center;
}

.top-casino {
    color: #4c4296;
    color: var(--link-color);
    font-family: "Gilroy", sans-serif !important;
    font-size: 24px;

}

@media only screen and (max-width: 768px) {
    .taglines-group {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    .taglines-group .group.footer-game-column{
        /* flex: 1; */
        flex-basis: 50%;
    }

    .taglines-group .group.footer-payments {
        width: 100%;
    }
    
}

@media only screen and (max-width: 480px) {
    .taglines-group .group.bottom-link {
        margin-left: 0rem !important;
    }
    
}