 i img {
     width: 98%;
     height: 98%;
 }

 .app-button {
     display: inline-block;
     margin-right: 3px;
     margin-left: 3px;
     border: 1px transparent;
     border-radius: 100%;
     width: 30px;
     height: 30px;
     -webkit-appearance: none;
 }

 .taglines-group .group.footer-payments {
     /* width: 45%; */
     display: flex;
     align-items: flex-end;
 }

 .group .tagline {
     color: #fff;
 }

 .taglines-group .group.footer-payments img {
     max-width: 100%;
 }

 .footer-dynamic.footer-follow-us-section {
     margin-top: 3rem;
 }

 .footer-follow-us-section .taglines-group {
     display: flex;
     align-items: flex-end;
     justify-content: flex-start;
 }

 .footer-follow-us-section .taglines-group .group.follow-us {
     display: flex;
     flex-wrap: wrap;
 }

 .footer-follow-us-section .taglines-group .group.follow-us .tagline.title {
     width: 100%;
 }

 .footer-follow-us-section .taglines-group .group.follow-us .tagline {
     margin-right: 1.2rem;
     margin-top: 1rem;
     cursor: pointer;
 }

 .footer-follow-us-section .taglines-group .group.follow-us .tagline {
     margin-right: 1.2rem;
     margin-top: 1rem;
     cursor: pointer;
 }

 .footer-follow-us-section .taglines-group .group.follow-us .tagline img {
     width: 25px;
 }


 .taglines-group .group.licensed {
     margin-left: 3rem;
 }

 .footer-dynamic.footer-text-section {
     margin-top: 3rem;
     margin-bottom: 1rem;
 }

 .footer-text-section .taglines-group .group.text .tagline {
     font-size: 12px;
     opacity: 0.4;
 }

 .footer-text-section .taglines-group .tagline-bottom {
     flex: 0 0 100%;
     max-width: 100%;
 }

 .tagline span {
     display: block;
     font-weight: 600;
 }


 .taglines-group .group.bottom-link {
     display: flex;
     flex-wrap: wrap;
     margin-left: 3rem;
 }

 .taglines-group .group.bottom-link .text-link {
     margin-right: 0.5rem;
 }

 .footer-dynamic.bottom-section .taglines-group .group .tagline {
     font-size: 14px;
 }

 .footer-dynamic.bottom-section .taglines-group .group.bottom-link .text-link span {
     white-space: nowrap;
 }

 .footer-dynamic.bottom-section .taglines-group .group.bottom-link .text-link span::after {
     content: "|";
     margin-left: 0.5rem;
 }