.styled-text-list.announcement {
    position: absolute;
    top: 70px;
    width: 100%;
    background-color: rgba(14, 7, 7, 0.2);
    z-index: 2;
    
}

.styled-text-list {
    min-height: unset !important;
}

.styled-text-list.announcement .containers {
    max-width: 100%;
}


.styled-text-list.announcement .containers .rows {
    align-items: center;
    margin-left: 8px;
    margin-right: 8px;
    flex-wrap: nowrap;
}

.rows {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.styled-text-list.announcement .containers .rows .tagline.announcement-icon {
    margin-bottom: 0;
    display: flex;
    z-index: 2;
}

.styled-text-list.announcement .containers .rows .tagline.announcement-icon img {
    width: 20px;
    margin-right: 15px;
}

.tagline span {
    display: block;
    font-weight: 600;
}

.styled-text-list.announcement .containers .rows .tagline.announcement-text {
    margin-bottom: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
}

.marquee-container {
    overflow-x: hidden !important;
    display: flex !important;
    flex-direction: row !important;
    position: relative;
    width: 100%;
}

/* .marquee {
    flex: 0 0 auto;
    min-width: 100%;
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    animation: scroll var(--duration) linear var(--delay) var(--iteration-count);
    animation-play-state: var(--play);
    animation-delay: var(--delay);
    animation-direction: var(--direction);
} */
.marquee {
    flex: 0 0 auto;
    min-width: 100%;
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    animation: scroll var(--duration) linear var(--delay) var(--iteration-count);
    animation-play-state: var(--play);
    animation-delay: var(--delay);
    animation-direction: var(--direction);
}

@keyframes scroll {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(-100%);
    }
}


/* ----hero banner------ */

.hero-carousel {
    border-radius: 0;
    background-size: cover;
    padding: 0;
    height: 395px;
    justify-content: center;
    
}


.text-wrapper {
    max-width: 570px;
    padding-left: 0;
}

.text-wrapper .tagline {
    margin: 0 auto;
}

.text-wrapper .tagline .title {
    font-weight: 600;
    font-size: 38px;
    line-height: 38px;
    margin-bottom: 0.5rem;
    color: #ffffff;
}

.text-wrapper .tagline .text {
    font-weight: 400;
    font-size: 20px;
    line-height: 36px;
    color: #fff;
}


.text-wrapper .tagline.btn {
    color: #fff;
    margin-top: 1rem;
}

.panel-icon {
    max-height: 395px;
    max-width: unset;
}

.btn.btn-cta.shadow {
    content: "";
    display: block;
    /* position: absolute; */
    top: -10px;
    bottom: -10px;
    left: -10px;
    right: -10px;
    border-radius: 40px;
    border: 10px solid rgba(156, 40, 176, 0.33);
}

.banner-jackpot .banner-jackpot-label {
    background-image: linear-gradient(180deg, #e3a127 50%, #e9aa35 50%, #ecd05a 20%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent;
    font-weight: 900;
    text-transform: uppercase !important;
    font-family: sans-serif;
    font-size: 20px;
    line-height: 40px;
}

.banner-jackpot {

    height: 30px;
    width: auto;
    display: inline-block;
    margin-top: 10px;
}

.banner-jackpot .jackpot-counter-container {
    min-width: unset;
    width: -moz-fit-content;
    width: fit-content;
}

.jackpot-counter-container {
    display: block;
    padding: 3px;
    background-image: linear-gradient(180deg, #cecece 0, #cbcac5 22%, #e8e8e8 45%, #f6f6f6 50%, #b6b6b6 55%, #868686 78%, #919191);
    border-radius: 15px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, .4);
    min-width: 200px;
}

.jackpot-number-group {
    display: block;
    overflow: hidden;
    text-align: center;
    border-radius: 4px;
    width: 100%;
    border-radius: 12px;
}

.jackpot-number-group .jackpot-number-container {
    box-shadow: inset 0 2px 5px rgba(0, 0, 0, .5);
    width: 100%;
    padding: 2px 10px;
    display: block;
    background: linear-gradient(180deg, #383831, #131313);
    color: #fff;
}

.jackpot-number-group .jackpot-number-container .jackpot-amount {
    letter-spacing: 5px;
    font-size: 30px;
    font-weight: 700;
    font-family: sans-serif;
    -webkit-font-feature-settings: "tnum";
    font-feature-settings: "tnum";
    font-variant-numeric: tabular-nums;
    background-image: linear-gradient(180deg, #462523 0, #ffe461 22%, #f8e69a 45%, #fff7e0 50%, #eead36 55%, #ffbb54 78%, #da4b0b);
    color: transparent;
    -webkit-background-clip: text;
}

/* ----hero banner end------ */