header {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 16;
}

.d-side-menu {
    background-color: #0e0e0f;
    color: #fff;

}


.language-menu-container .dropdown {
    width: 55px;
    position: relative;
    display: block;
    margin-right: 0.8rem;
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
}

.language-menu-container .dropbtn {
    color: var(--secondary-text-popup);
    background: transparent;
    white-space: nowrap;
    border: 1px solid var(--secondary-text-popup);
}

.language-menu-container .dropbtn {
    color: var(--top-menu-text);
    background: transparent;
    white-space: nowrap;
    border: 1px solidvar(--secondary-text-popup);
    border-radius: 40px;
    display: flex;
    align-items: center;
    height: auto;
    width: 56px;
    cursor: pointer;
}

.language-menu-container .dropdownItem.active {
    border-top: none;
}

.language-menu-container .dropbtn .dropdownItem {
    height: auto;
    padding: 5px;
}




.language-menu-container .dropdownItem:first-child :hover {
    border-top-right-radius: 0.3rem;
    border-top-left-radius: 0.3rem;
}

.language-menu-container .dropdownItem .item.active {
    justify-content: center;
    padding: 0;
}


.language-menu-container .dropdownItem .item {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 15px 0;
    font-size: .875rem;
}

.flag-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.language-menu-container .dropdownItem .flagIcon {
    background: transparent;
    white-space: nowrap;
    border: 0 solid var(--top-menu-border);
    border-radius: 100%;
    display: flex;
    align-items: center;
    height: 20px;
    width: 24px;
    overflow: hidden;
    left: 5px;
    font-size: 15px;
    text-align: center;
}

.material-icons {
    color: var(--secondary-text-popup);
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}

.arrow-icon {
    display: block;
    width: 20px;
    height: 20px;
}

.arrow-icon .material-icons {
    font-size: 22px;
}

.language-menu-container .dropdown-content:last-child {
    border-radius: 0.3rem;
    min-width: 120px;
    width: 100%;
}

.language-menu-container .dropdown-content {
    left: -67px;
    top: 34px;
}

.language-menu-container .dropdown-content {
    display: none;
    position: absolute;
    background-color: var(--popup-background);
    transition: background .3s ease-in-out;
    z-index: 10;
    color: var(--popup-text);
    cursor: pointer;
    text-align: center;
    width: 100%;
    border-radius: 0.3rem;
    top: 45px;
    height: auto;
    left: -35px;
}

.language-menu-container .dropdownItem:not(:last-child) {
    border-bottom: 1px solid var(--light-border);
}

.language-menu-container .dropdown-content .dropdownItem {
    border-top: none;
}

.language-menu-container .dropdownItem {
    width: 100%;
    display: block;
}

.language-menu-container .dropdownItem .item {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 15px 0;
    font-size: .875rem;
}

.language-menu-container .dropdownItem .lang-icon {
    width: 29px;
    display: inline-block;
}

.side-menu-content .image {
    width: 100%;
    height: 150px;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: 50%;
}

.image {
    background-image: url(https://images.ctfassets.net/scslmvjv6lim/31MYtU6g1dACrt6jzJ1r7F/539bda07d4ebb8cf1de18ea677030c93/bet88ph-280x60.png);
}

.side-menu-content .image {
    height: 40px;
}

.side-menu-content .title {
    font-weight: 600;
    font-size: 30px;
}

.side-menu-content>div {
    color: #fff;
}

.d-side-menu .side-menu-content {
    text-align: center;
}

.d-side-menu .side-menu-content .tagline {
    font-size: 14px;
    opacity: .7;
}

.d-side-menu .logged-out-items {
    padding: 2em 2em 0;
    text-align: center;
}

.d-side-menu .logged-out-items .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase !important;
    color: #fff;
}

.d-side-menu .logged-out-items .btn {
    margin-bottom: 10px;
}

.btn.btn-cta,
a.btn:not([href]):not([tabindex]).btn-cta,
button.btn.btn-cta {
    background-color: #ef14c8;
    background-color: var(--cta);
    color: #fff;
    color: var(--cta-text);
    border: none;
    border: var(--cta-border);
    box-shadow: none;
    text-transform: uppercase;
}

.btn,
a.btn:not([href]):not([tabindex]),
button.btn {
    border-radius: 2.5rem;
    border-radius: var(--button-border-radius);
    font-family: Asap, sans-serif;
    font-family: var(--button-font);
    letter-spacing: 0;
    letter-spacing: var(--button-letter-spacing);
    box-shadow: none;
    border: none;
    font-size: 16px;
    text-transform: uppercase;
    height: 54px;
    padding: 15px;
    min-width: 192px;
    font-weight: 600;
    cursor: pointer;
    white-space: nowrap;

}

.d-side-menu .logged-out-items .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase !important;
    color: #fff;
}

.btn.btn-secondary-popup,
a.btn:not([href]):not([tabindex]).btn-secondary-popup,
button.btn.btn-secondary-popup {
    background-color: transperant;
    background-color: var(--secondary-popup);
    color: #707070;
    color: var(--secondary-text-popup);
    border: 1px solid rgba(0, 0, 0, .12) !important;
    border: var(--secondary-border-popup) !important;
}

.headerRight .lngAndButtonWrap a {
    background-color: transperant;
    background-color: var(--secondary);
    color: hsla(0, 0%, 100%, .8);
    color: var(--secondary-text);
    border: 1px solid hsla(0, 0%, 100%, .3) !important;
    border: var(--secondary-border) !important;
    height: 40px;
    padding: 13px 37px;
    box-shadow: none;
    min-width: 126px;
}

.d-side-menu .menu-items {
    width: 100%;
    padding: 2em;
    -webkit-user-select: none;
    user-select: none;
}

.d-side-menu .nav-link {
    color: #fff;
    text-transform: uppercase;
}

.d-side-menu .nav-link {
    color: #fff;
    text-transform: uppercase;
    font-weight: 500;
    font-family: var(--navigation-font);
}

.nav-link {
    display: block;
    padding: 0.5rem 1rem;

}

a {
    color: #4c4296;
    color: var(--link-color);
}

a {
    color: #007bff;
    text-decoration: none;
    background-color: initial;
}

.d-side-menu .footer-items {
    background: #0e0e0f;
    border-top-color: #38343a;
    display: flex;
    flex-direction: column;
}

.d-side-menu .footer-items {
    border-top: 1px solid #ddd;
    padding: 20px 0;
    width: 80%;
    margin: 0 10%;
    /* background: var(--popup-background); */
}

.d-side-menu .footer-items .nav-link {
    color: #fff;
    font-size: 16px;
    padding-left: 20px;
    font-size: 100%;
}


.d-side-menu .nav-link {
    color: #fff;
    text-transform: uppercase;
}

.d-side-menu .nav-link {
    /* color: var(--popup-link-color); */
    text-transform: uppercase;
    font-weight: 500;
    font-family: var(--navigation-font);
}

.d-side-menu .footer-items .nav-link {
    color: #fff;
    font-size: 16px;
    padding-left: 20px;
    font-size: 100%;
}

.footer-items .app-install-container {
    padding-left: 20px;
    order: 1;
}

.footer-items .app-install-container {
    margin-top: 15px;
}

.app-buttons-container {
    display: inline-flex;
    flex-wrap: wrap;
}

.app-buttons-container .download-text-area {
    width: 100%;
    cursor: pointer;
    display: none;
}

.android {
    background: linear-gradient(225deg, #9dd201, #627f09);
}

.app-button {
    display: inline-block;
    margin-right: 3px;
    margin-left: 3px;

    border: 1px transparent;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    -webkit-appearance: none;
}

.app-button i {
    vertical-align: middle;
}

.app-button i img {
    width: 75%;
    height: 75%;
}

.desktop-top-menu-nav {
    order: 1;
    /* width: 100%; */
    display: flex;
    align-items: center;
    justify-content: left;
}

.desktop-top-menu-nav .nav-link {
    -webkit-user-select: none;
    user-select: none;
    font-family: Asap, sans-serif;
    font-family: var(--navigation-font);
    color: #fff;
    color: var(--top-menu-text);
    background: transparent;
    white-space: nowrap;
    border: 0;
    display: inline-block;
    margin-left: 40px;
    text-transform: uppercase;
    font-size: 14px;
    padding: 17px 0 3px;
    margin-top: -15px;
    /* font-weight: 600; */
}

/* logo */
.desktop-logo-container {
    display: flex;
    justify-content: center;
}

.desktop-logo-container {
    order: 2;
    align-items: center;
    width: 100%;
    text-align: center;
}

.desktop-logo-container .logo {
    height: auto;
    max-width: 160px;
    width: 160px;
}

.main-menu-container .dark-logo {
    display: none;
}

.desktop-logo-container .logo {
    background-size: auto 100%;
    background-repeat: no-repeat;
    width: 140px;
    font-family: Asap, sans-serif;
    font-family: var(--button-font);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 150%;
}



/* user panel */
.desktop-user-panel {
    order: 3;
    width: 100%;
    padding-top: 3px;
}


.lngAndButtonWrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.language-menu-container .dropdown {
    width: 55px;
    position: relative;
    display: block;
    margin-right: 0.8rem;
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
}

.language-menu-container .dropbtn {
    color: var(--top-menu-text);
    background: transparent;
    white-space: nowrap;
    border: 1px solid var(--top-menu-border);
    border-radius: 40px;
    display: flex;
    align-items: center;
    height: auto;
    width: 56px;
    cursor: pointer;
}

.language-menu-container .dropdownItem.active {
    border-top: none;
}

.language-menu-container .dropbtn .dropdownItem {
    height: auto;
    padding: 5px;
}

.language-menu-container .dropdownItem {
    width: 100%;
    display: block;
}

.language-menu-container .dropdownItem .item.active {
    justify-content: center;
    padding: 0;
}

.language-menu-container .dropdownItem .item {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 15px 0;
    font-size: .875rem;
}


.language-menu-container .dropdownItem .flagIcon {
    background: transparent;
    white-space: nowrap;
    border: 0 solid var(--top-menu-border);
    border-radius: 100%;
    display: flex;
    align-items: center;
    height: 20px;
    width: 24px;
    overflow: hidden;
    left: 5px;
    font-size: 15px;
    text-align: center;
}


.main-menu-container a.btn:not([href]):not([tabindex]).small {
    font-size: 16px;
    height: auto;
    padding: 6px 12px;
    line-height: normal;
}

.main-menu-container .btn.small {
    font-size: 16px;
    height: auto;
    padding: 6px 12px;
    line-height: normal;
}

.btn.btn-secondary,
a.btn:not([href]):not([tabindex]).btn-secondary,
button.btn.btn-secondary {
    background-color: transperant;
    background-color: var(--secondary);
    color: hsla(0, 0%, 100%, .8);
    color: var(--secondary-text);
    border: 1px solid hsla(0, 0%, 100%, .3) !important;
    border: var(--secondary-border) !important;
    height: 54px;
    padding: 13px 37px;
    box-shadow: none;
}

.btn.btn-cta,
a.btn:not([href]):not([tabindex]).btn-cta,
button.btn.btn-cta {
    background-color: #ef14c8;
    background-color: var(--cta);
    color: #fff;
    color: var(--cta-text);
    border: none;
    border: var(--cta-border);
    box-shadow: none;
    text-transform: uppercase;
}

.btn,
a.btn:not([href]):not([tabindex]),
button.btn {
    border-radius: 2.5rem;
    border-radius: var(--button-border-radius);
    font-family: Asap, sans-serif;
    font-family: var(--button-font);
    letter-spacing: 0;
    letter-spacing: var(--button-letter-spacing);
    box-shadow: none;
    border: none;
    font-size: 16px;
    text-transform: uppercase;
    height: 54px;
    padding: 15px;
    min-width: 192px;
    font-weight: 600;
    cursor: pointer;
    white-space: nowrap;
}

.headerRight {
    order: 3;
    width: 33.3333%;
}

.header-Right.login-sm-btn .login-button {
    background-color: transperant;
    background-color: var(--secondary);
    color: hsla(0, 0%, 100%, .8);
    color: var(--secondary-text);
    border: 1px solid hsla(0, 0%, 100%, .3) !important;
    border: var(--secondary-border) !important;
    height: 54px;
    padding: 13px 37px;
    box-shadow: none;
}

.main-menu-container .lngAndButtonWrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.main-menu-container .lngAndButtonWrap .btn.small.login-button,
.main-menu-container .lngAndButtonWrap a.btn:not([href]):not([tabindex]).small.login-button,
.main-menu-container {
    min-width: unset;
    padding-left: 15px;
    padding-right: 15px;
}

/* user panel end */