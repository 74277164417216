/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:wght@400;500;600&family=Rajdhani:wght@400;500;600&display=swap');

@font-face {
  font-family: "Gilroy", sans-serif !important;
  src: url(/public//assets/fonts/Radomir+Tinkov+-+Gilroy-HeavyItalic.otf);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: #000;
  color: #fff;
  background: var(--main-background);
  font-family: Asap, sans-serif;
  font-family: var(--text-font);
}

:root {
  --main-background: #000;
  --cta: #720494;
  --cta-text: #fff;
  --cta-hover: #941bb9;
  --cta-active: #4b0460;
  --cta-disabled: #d3d3d3;
  --cta-border: none;
  --primary: #4e3a62;
  --primary-text: #fff;
  --primary-hover: #63497c;
  --primary-active: #2f233a;
  --primary-disabled: #d3d3d3;
  --primary-border: none;
  --secondary: rgba(117, 11, 149, 0.3);
  --secondary-text: #fff;
  --secondary-hover: #000;
  --secondary-disabled: #d3d3d3;
  --secondary-border: 2px solid #720494;
  --secondary-popup: transperant;
  --secondary-text-popup: #720494;
  --secondary-hover-popup: rgba(117, 11, 149, 0.3);
  --secondary-border-popup: 1px solid #720494;
  --secondary-hover-text-popup: #720494;
  --secondary-text-popup-disabled: #2d303d;
  --disabled-text-color: rgba(255, 255, 255, 0.85);
  --account-page-menu-container: #272c48;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #c20202;
  --panel-background: #000;
  --panel-background-dark: #000;
  --panel-text: #fff;
  --input-icon: rgba(0, 0, 0, 0.5);
  --input-icon-highlight: #720494;
  --cta-link-text: #c209fc;
  --popup-background: #fff;
  --popup-wrapper-background: #f7f7f7;
  --popup-text: #666;
  --popup-dark-text: #000;
  --popup-search: #fff;
  --popup-search-selected: #1e2238;
  --popup-link-color: #272c48;
  --text: #fff;
  --progress-background: #e7e7e7;
  --progressbar-background: #720494;
  --link-color: #c209fc;
  --link-hover-color: #941bb9;
  --light-border: #ddd;
  --user-menu-item: #272c48;
  --user-menu-item-text: #720494;
  --user-menu-item-hover: #394169;
  --user-menu-item-hover-text: #720494;
  --top-menu-start: #000;
  --top-menu: #000;
  --top-menu-mobile-start: rgba(0, 0, 0, 0);
  --top-menu-mobile: #000;
  --top-menu-text: #fff;
  --top-menu-text-active: #c209fc;
  --top-menu-border: rgba(255, 255, 255, 0.4);
  --top-menu-mobile-item-color: #fff;
  --input-field: #fff;
  --input-text-color: #000;
  --input-border-radius: 5px;
  --input-focus-text-color: #000;
  --input-focus-border: 1px solid #000;
  --input-border: 1px solid rgba(0, 0, 0, 0.12);
  --input-focus: #f8f7fb;
  --button-border-radius: 40px;
  --input-error-border: 1px solid #c20202;
  --input-error-feeback: #c20202;
  --search-input: rgba(0, 0, 0, 0.4);
  --search-input-text: #efeff0;
  --navigation-mobile-logged-in-icon: #000;
  --navigation-font: Rajdhani, sans-serif;
  --navigation-icon: #fff;
  --nav-link-text: #fff;
  --nav-link: rgba(0, 0, 0, 0);
  --nav-link-active: rgba(0, 0, 0, 0);
  --nav-link-text-active: #fff;
  --nav-link-text-hover: #fff;
  --nav-link-hover: rgba(26, 26, 26, 0);
  --drop-down: #fff;
  --drop-down-selected: #666;
  --game-controls: #fff;
  --game-controls-active: #720494;
  --footer: rgba(0, 0, 0, 0);
  --footer-text: #fff;
  --content-steps: #fff;
  --content-steps-text: #fff;
  --button-font: Gilroy, sans-serif;
  --header-font: Rajdhani, sans-serif;
  --text-font: Rajdhani, sans-serif;
  --banner-text: #fff;
  --footer-text-font: Rajdhani, sans-serif;
  --button-letter-spacing: 0;
  --header-letter-spacing: 0;
  --text-letter-spacing: 0;
  --navigation-letter-spacing: 0;
  --input-letter-spacing: 0;
  --dark-text: #000;
  --light-text: #666;
  --light-background: #fff;
  --extra-light-text: #b3b3b3;
  --footerinfo-background: #000;
  --footerinfo-text: #fff;
  --active-list: #6c757d;
  --page-background: #000;
  --head-bigscreen-height: 55px;
  --head-smallscreen-height: 55px;
  --ribbon-background: #ff9b08;
  --ribbon-background-shadow: #d47e00;
  --ribbon-text: #fff;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
}

:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

:root {
  --main-background: #000;
  --cta: #720494;
  --cta-text: #fff;
  --cta-hover: #941bb9;
  --cta-active: #4b0460;
  --cta-disabled: #d3d3d3;
  --cta-border: none;
  --primary: #4e3a62;
  --primary-text: #fff;
  --primary-hover: #63497c;
  --primary-active: #2f233a;
  --primary-disabled: #d3d3d3;
  --primary-border: none;
  --secondary: rgba(117, 11, 149, 0.3);
  --secondary-text: #fff;
  --secondary-hover: #000;
  --secondary-disabled: #d3d3d3;
  --secondary-border: 2px solid #720494;
  --secondary-popup: transperant;
  --secondary-text-popup: #720494;
  --secondary-hover-popup: rgba(117, 11, 149, 0.3);
  --secondary-border-popup: 1px solid #720494;
  --secondary-hover-text-popup: #720494;
  --secondary-text-popup-disabled: #2d303d;
  --disabled-text-color: rgba(255, 255, 255, 0.85);
  --account-page-menu-container: #272c48;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #c20202;
  --panel-background: #000;
  --panel-background-dark: #000;
  --panel-text: #fff;
  --input-icon: rgba(0, 0, 0, 0.5);
  --input-icon-highlight: #720494;
  --cta-link-text: #c209fc;
  --popup-background: #fff;
  --popup-wrapper-background: #f7f7f7;
  --popup-text: #666;
  --popup-dark-text: #000;
  --popup-search: #fff;
  --popup-search-selected: #1e2238;
  --popup-link-color: #272c48;
  --text: #fff;
  --progress-background: #e7e7e7;
  --progressbar-background: #720494;
  --link-color: #c209fc;
  --link-hover-color: #941bb9;
  --light-border: #ddd;
  --user-menu-item: #272c48;
  --user-menu-item-text: #720494;
  --user-menu-item-hover: #394169;
  --user-menu-item-hover-text: #720494;
  --top-menu-start: #000;
  --top-menu: #000;
  --top-menu-mobile-start: rgba(0, 0, 0, 0);
  --top-menu-mobile: #000;
  --top-menu-text: #fff;
  --top-menu-text-active: #c209fc;
  --top-menu-border: rgba(255, 255, 255, 0.4);
  --top-menu-mobile-item-color: #fff;
  --input-field: #fff;
  --input-text-color: #000;
  --input-border-radius: 5px;
  --input-focus-text-color: #000;
  --input-focus-border: 1px solid #000;
  --input-border: 1px solid rgba(0, 0, 0, 0.12);
  --input-focus: #f8f7fb;
  --button-border-radius: 40px;
  --input-error-border: 1px solid #c20202;
  --input-error-feeback: #c20202;
  --search-input: rgba(0, 0, 0, 0.4);
  --search-input-text: #efeff0;
  --navigation-mobile-logged-in-icon: #000;
  --navigation-font: Rajdhani, sans-serif;
  --navigation-icon: #fff;
  --nav-link-text: #fff;
  --nav-link: rgba(0, 0, 0, 0);
  --nav-link-active: rgba(0, 0, 0, 0);
  --nav-link-text-active: #fff;
  --nav-link-text-hover: #fff;
  --nav-link-hover: rgba(26, 26, 26, 0);
  --drop-down: #fff;
  --drop-down-selected: #666;
  --game-controls: #fff;
  --game-controls-active: #720494;
  --footer: rgba(0, 0, 0, 0);
  --footer-text: #fff;
  --content-steps: #fff;
  --content-steps-text: #fff;
  --button-font: Gilroy, sans-serif;
  --header-font: Rajdhani, sans-serif;
  --text-font: Rajdhani, sans-serif;
  --banner-text: #fff;
  --footer-text-font: Rajdhani, sans-serif;
  --button-letter-spacing: 0;
  --header-letter-spacing: 0;
  --text-letter-spacing: 0;
  --navigation-letter-spacing: 0;
  --input-letter-spacing: 0;
  --dark-text: #000;
  --light-text: #666;
  --light-background: #fff;
  --extra-light-text: #b3b3b3;
  --footerinfo-background: #000;
  --footerinfo-text: #fff;
  --active-list: #6c757d;
  --page-background: #000;
  --head-bigscreen-height: 55px;
  --head-smallscreen-height: 55px;
  --ribbon-background: #ff9b08;
  --ribbon-background-shadow: #d47e00;
  --ribbon-text: #fff;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
}